import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {environment} from 'src/environments/environment';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {TranslateService} from '@ngx-translate/core';
import {ModalController} from '@ionic/angular';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {SelectTableModalComponent} from '../select-table-modal/select-table-modal.component';

@Component({
	selector: 'app-universal-page-toolbar',
	templateUrl: './universal-page-toolbar.component.html',
	styleUrls: ['universal-page-toolbar.component.scss']
})
export class UniversalPageToolbarComponent implements OnInit {
	@Input() venue: Venue;
	@Input() order: Order;
	@Input() logo = false;
	@Input() backButton = false;
	@Input() burgerButton = false;
	@Input() crossButton = false;
	@Input() lang = false;
	@Input() table = false;
	@Input() title = '';

	@Output() backClick = new EventEmitter<void>();
	@Output() close = new EventEmitter<void>();

	pt = PreorderType;

	constructor(
		public translate: TranslateService,
		private modalCtrl: ModalController,
		private repository: RepositoryService
	) {}

	ngOnInit() {}
	// async selectLanguageModal() {
	// 	await SelectLanguageModalComponent.show(this.modalCtrl);
	// }
	async openSelectTableModal() {
		if (this.venue && this.order.preorder.type === PreorderType.INSIDE) {
			const selectTable = await SelectTableModalComponent.show(this.modalCtrl, this.venue, this.order);
			if (selectTable) {
				this.order.tableNumber = selectTable.table;
				this.order.table = selectTable.tableId;
				this.repository.order.emit(this.order);
			}
		}
	}
}

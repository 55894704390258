import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ArticleOptionGroupFlavorComponent} from '../components/article-option-group-flavor/article-option-group-flavor.component';
import {ItemSelectableArticleComponent} from '../components/item-selectable-article/item-selectable-article.component';
import {ToOrderButtonComponent} from '../components/to-order-button/to-order-button.component';
import {OrderListComponent} from '../components/order-list/order-list.component';
import {ToggleButtonGroupComponent} from '../components/toggle-button-group/toggle-button-group.component';
import {MenuPopoverComponent} from '../components/menu-popover/menu-popover.component';
import {FormsModule} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MyOrderComponent} from '../components/my-order/my-order.component';
import {QRCodeModule} from 'angular2-qrcode';
import {ItemViewComponent} from '../components/item-view/item-view.component';
import {ArticleOptionGroupSingleComponent} from '../components/article-option-group-single/article-option-group-single.component';
import {VenueSuggestionComponent} from '../components/venue-suggestion/venue-suggestion.component';
import {HomePopoverComponent} from '../components/home-popover/home-popover.component';
import {ArticleOptionGroupCheckboxComponent} from '../components/article-option-group-checkbox/article-option-group-checkbox.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {createTranslateLoader} from '../app.module';
import {BurgerBtnComponent} from '../components/burger-btn/burger-btn.component';
import {ArticleOptionGroupMultipleComponent} from '../components/article-option-group-multiple/article-option-group-multiple.component';
import {RouterModule} from '@angular/router';
import {OrderContentComponent} from '../components/order-content/order-content.component';
import {PromoCodeComponent} from '../components/promo-code/promo-code.component';
import {PaymentMethodsDisplayComponent} from '../components/payment-methods-display/payment-methods-display.component';
import {CustomAddressModalComponent} from '../components/custom-address-modal/custom-address-modal.component';
import {AllowGpsModalComponent} from '../components/allow-gps-modal/allow-gps-modal.component';
import {TipComponent} from '../components/tip/tip.component';
import {LangStringPipe} from '../../smoothr-web-app-core/pipes/lang-string.pipe';
import {TagComponent} from '../components/tag/tag.component';
import {QuantitySelectorComponent} from '../components/quantity-selector/quantity-selector.component';
import {PreorderTypeChipComponent} from '../components/preorder-type-chip/preorder-type-chip.component';
import {AccountToolbarComponent} from '../components/account-toolbar/account-toolbar.component';
import {SlotsSelectorComponent} from '../components/slots-selector/slots-selector.component';
import {MenuModalComponent} from '../components/menu-modal/menu-modal.component';
import {NoteArticleModalComponent} from '../components/note-article-modal/note-article-modal.component';
import {LangObservablePipe} from '../../smoothr-web-app-core/pipes/lang-obervable.pipe';
import {AppRestorePasswordModalComponent} from '../components/app-restore-password-modal/app-restore-password-modal.component';
import {SelectTableModalComponent} from '../components/select-table-modal/select-table-modal.component';
import {ChipTableComponent} from '../components/chip-table/chip-table.component';
import {AddCustomTipComponent} from '../components/add-custom-tip/add-custom-tip.component';
import {RecomendationArticleModalComponent} from '../components/recomendation-article-modal/recomendation-article-modal.component';
import {ConfirmYearModalComponent} from '../components/confirm-year-dialog/confirm-year-modal.component';
import {OrderPageToolbarComponent} from '../components/order-page-toolbar/order-page-toolbar.component';
import {UniversalPageToolbarComponent} from '../components/universal-page-toolbar/universal-page-toolbar.component';

@NgModule({
	declarations: [
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		MenuPopoverComponent,
		VenueSuggestionComponent,
		MyOrderComponent,
		HomePopoverComponent,
		ItemViewComponent,
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupMultipleComponent,
		BurgerBtnComponent,
		OrderContentComponent,
		PromoCodeComponent,
		PaymentMethodsDisplayComponent,
		CustomAddressModalComponent,
		AllowGpsModalComponent,
		TagComponent,
		TipComponent,
		LangStringPipe,
		LangObservablePipe,
		QuantitySelectorComponent,
		PreorderTypeChipComponent,
		AccountToolbarComponent,
		SlotsSelectorComponent,
		MenuModalComponent,
		NoteArticleModalComponent,
		AppRestorePasswordModalComponent,
		SelectTableModalComponent,
		ChipTableComponent,
		AddCustomTipComponent,
		RecomendationArticleModalComponent,
		ConfirmYearModalComponent,
		OrderPageToolbarComponent,
		UniversalPageToolbarComponent
	],
	exports: [
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ArticleOptionGroupMultipleComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		HomePopoverComponent,
		VenueSuggestionComponent,
		MenuPopoverComponent,
		BurgerBtnComponent,
		MyOrderComponent,
		ItemViewComponent,
		TranslateModule,
		OrderContentComponent,
		PromoCodeComponent,
		CustomAddressModalComponent,
		AllowGpsModalComponent,
		LangStringPipe,
		LangObservablePipe,
		TagComponent,
		QuantitySelectorComponent,
		PreorderTypeChipComponent,
		AccountToolbarComponent,
		SlotsSelectorComponent,
		MenuModalComponent,
		NoteArticleModalComponent,
		TipComponent,
		AppRestorePasswordModalComponent,
		SelectTableModalComponent,
		ChipTableComponent,
		AddCustomTipComponent,
		RecomendationArticleModalComponent,
		ConfirmYearModalComponent,
		OrderPageToolbarComponent,
		UniversalPageToolbarComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		MatSnackBarModule,
		QRCodeModule,
		TranslateModule.forChild({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		RouterModule
	]
})
export class SharedModuleModule {}

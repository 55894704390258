import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-confirm-year-modal',
	templateUrl: './confirm-year-modal.component.html',
	styleUrls: ['confirm-year-modal.component.scss']
})
export class ConfirmYearModalComponent implements OnInit {
	public selectedLanguage: string;
	public canDrinkCheckbox: boolean = false;

	public listCountries = [
		{
			name: 'English',
			icon: '../../../assets/elda/en.svg',
			value: 'en'
		},

		{
			name: 'Icelandic',
			icon: '../../../assets/elda/is.svg',
			value: 'is'
		}
	];
	constructor(
		private translate: TranslateService,
		private modalCtrl: ModalController
	) {
		this.selectedLanguage = this.translate.currentLang;
	}
	static async show(modalCtrl: ModalController): Promise<boolean> {
		const modal = await modalCtrl.create({
			component: ConfirmYearModalComponent,
			cssClass: 'auto-height confirm-year-modal',

			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		return result;
	}
	ngOnInit() {}
	selectLng(lang: string) {
		this.selectedLanguage = lang;
		// this.translate.setDefaultLang(this.selectedLanguage);
		// this.translate.use(this.selectedLanguage);
		// this.dismiss();
	}
	dismissWithData() {
		this.modalCtrl.dismiss(this.canDrinkCheckbox);
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
}

import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {ActivatedRoute, Router} from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {sleep, venueAcceptsOrders} from '../../../smoothr-web-app-core/utils/utils';
import {MenuPage} from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {MatSnackBar} from '@angular/material/snack-bar';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.jamies.html',
	styleUrls: ['./home.page.jamies.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = 'scan/:venueId/:tableNumber';

	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		public translate: TranslateService,
		private route: ActivatedRoute,
		private snackBar: MatSnackBar
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		await router.navigateByUrl(HomePage.url);
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.loadVenues();
		this.route.paramMap.subscribe(params => {
			console.log(params, params.get('tableNumber'));
			if (params && params.get('tableNumber') && params.get('venueId')) {
				if (params.get('tableNumber') !== 'take_away') {
					this.loadVenueByQrCode(params.get('tableNumber'), params.get('venueId'));
				}
			}
		});
	}

	ionViewDidEnter() {
		this.repository.order.emit(null);
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading = true;
		console.log(await this.repository.getAllVenues());
		const venue = (await this.repository.getAllVenues()).find(ven => venueAcceptsOrders(ven, preorderType));
		if (!venue) {
			console.log('No available venues');
			this.loading = false;
			return;
		}
		await this.loadVenueAndCreateOrder(venue, null, preorderType);
	}

	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType, null);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues();
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						console.log(venue);
						this.active[preorderType] = this.active[preorderType] || venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => (this.loading = false));
	}
	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async loadVenueByQrCode(tableNumber: string, venueId: string) {
		this.loading = true;
		try {
			const venueData = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(this.venue._id, tableNumber)).data as any;
			await sleep(100);

			if (venueData && table) {
				if (!table?.active) {
					this.loading = false;
					this.snackBar.open(this.translate.instant('home_page.scan_table_error'), null, {
						duration: 2000
					});
					return;
				}
				this.repository.venue.emit(venueData);
				this.repository.createOrder(this.venue, null, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				sleep(200);
				await MenuPage.navigate(this.router);
			}
			this.loading = false;
		} catch (e) {
			this.snackBar.open(this.translate.instant('home_page.scan_table_error'), null, {
				duration: 2000
			});
			this.loading = false;
		}
	}
}
